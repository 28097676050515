





















































































import router from "@/router";
import { Component, Vue } from "vue-property-decorator";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { BButton, BFormInput, BFormGroup, BForm } from "bootstrap-vue";
import { Equipe, CreateEquipeModel } from "@/api/models/options/equipes/equipe";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
  },
})
export default class ListEquipes extends Vue {
  required = required;
  min = min;
  max = max;

  selected: any = null;

  listEquipes: PaginatedList<Equipe> =
    new PaginatedList<Equipe>();

  newEquipe: CreateEquipeModel = {}

  async created() {
    await this.loadEquipes({})
  }

  async loadEquipes(params: any) {
    await this.$http.myressif.equipes
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10
      )
      .then((response: PaginatedList<Equipe>) => {
        this.listEquipes = response;
      });
  }

  goDetails(equipeId: any): any {
    router.push({
      name: "admin-equipes-details",
      params: { equipeId: equipeId },
    });
  }

  async confirmCreate(event: any) {
    event.preventDefault();

    (this.$refs.createFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess: boolean) => {
        if (isSuccess) {
          await this.$http.myressif.equipes.post(this.newEquipe).then(
            async (response: any) => {
              this.$bvModal.hide("modal-post-equipe");
              successAlert.fire({
                title: "Création d'une équipe",
                text: "Création effectuée avec succès",
              });

              router.push({
                name: "admin-equipes-details",
                params: { equipeId: response },
              });
            },
            (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                this.$bvModal.hide("modal-post-equipe");
                errorAlert.fire({
                  text: error.message,
                });
              }
            }
          );
        }
      });
  }

  async deleteEquipe(equipeId: string) {
    this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer l\'équipe ?', {
          title: 'Confirmation de suppression',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value === true){
            await this.confirmDelete(equipeId)
          } 
          else {
            return
          }
        })
  }

  async confirmDelete(equipeId: string) {
    await this.$http.myressif.equipes.delete(equipeId)
      .then(async(response: string) => {
        successAlert.fire({
          title: "Suppression d\'une équipe",
          text: "Suppression effectuée avec succès"
        })

        await this.loadEquipes({})
      }, (error: any) => {
          errorAlert.fire({
            text: error.message,
          })
      })
  }
}
